<template>
  <section>
    <b-table-simple id="guides-table" responsive borderless fixed>
      <thead>
        <tr>
          <th v-for="header in tableHeaders" :key="header" scope="col">
            <label class="checkbox" v-if="header === 'checkbox' && tableRows.length">
              <input type="checkbox" v-model="selectAll" @click="selectAllChecks" />
              <span class="checkmark">
                <Check />
              </span>
            </label>
            <span v-else-if="header !== 'checkbox'"> 
              <b-row class="cursor-pointer" >
                {{ header }}
                <div 
                  v-if="Object.keys(headerOrder).find(head => head === header)"
                  @click="handleOrder(headerOrder[header])"
                >
                  <OrdenateIconHandler 
                    :position="orderBy.column === headerOrder[header] ? orderBy.order :  null" 
                    class="ml-2"
                  />
                </div>
              </b-row>
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(row, index) in tableRows" :key="index">
        <tr>
          <td v-for="(header, i) in tableHeaders" :key="header">
            <label class="checkbox" v-if="i === 0">
              <input
                type="checkbox"
                v-model="selected"
                :value="row"
                @click="selectCheck(row)"
              />
              <span class="checkmark">
                <Check />
              </span>
            </label>
            <span v-else-if="header === 'Arquivo'" class="tag" :style="`background: ${colors[row[header]]}`">
              {{ row[header] }}
            </span>
            <div v-else-if="header === 'Conferido'">
              <span :id="`popover-${index}`" class="tag bold" :class="{
                red:
                  parseInt(
                    row[header]['Porcentagem'].replace(/[^0-9]/g, '')
                  ) < 10,
                orange:
                  parseInt(
                    row[header]['Porcentagem'].replace(/[^0-9]/g, '')
                  ) < 20,
                yellow:
                  parseInt(
                    row[header]['Porcentagem'].replace(/[^0-9]/g, '')
                  ) < 50,
                blue:
                  parseInt(
                    row[header]['Porcentagem'].replace(/[^0-9]/g, '')
                  ) < 80,
                green:
                  parseInt(
                    row[header]['Porcentagem'].replace(/[^0-9]/g, '')
                  ) >= 80
              }">
                {{ row[header]['Porcentagem'] }}
              </span>
              <b-popover :target="`popover-${index}`" placement="bottom" triggers="hover focus">
                <p>
                  Guias conferidas:
                  <span class="conferred">{{ row[header]['Conferidas'] }}</span>
                </p>
                <p>
                  Guias não conferidas:
                  <span class="not-conferred">
                    {{ row[header]['Não conferidas'] }}
                  </span>
                </p>
              </b-popover>
            </div>
            <div
              v-else-if="i === 11"
              class="options"
              @mouseover="() => { if(index === tableRows.length-1) openedMenu = true }"
              @mouseout="openedMenu = false"
            >
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button variant="link" @click="findTissBatchById(row.id)">
                  Ver guias do lote
                </b-button>
                <b-button v-can="'FatTissLotAEnv4'" variant="link remove mb-0" @click="openDelete(row)">
                  Excluir lote
                </b-button>
              </div>
            </div>
            <p v-else>{{ row[header] }}</p>
          </td>
        </tr>
      </tbody>
      <tr>
        <td :class="{ 'hide-space':!openedMenu, 'spacing': openedMenu }"></td>
      </tr>
    </b-table-simple>

    <DeleteTissModal :number="batchNumber" :clinicHealthPlanName="clinicHealthPlanName"
      :confirmDelete="confirmDelete" />

    <BatchModal 
      :openHistoric="openHistoric"
      :getGuide="getGuide"
      :tissBatch="tissBatch" 
      :getTypes="getTypes" 
    />
  </section>
</template>

<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'
import Check from '@/assets/icons/check.svg'

export default {
  components: {
    MoreVertical,
    Check,
    DeleteTissModal: () => import('@/components/Tiss/Modal/DeleteTissModal.vue'),
    BatchModal: () => import('@/components/Tiss/Modal/BatchModal.vue'),
    OrdenateIconHandler: () => import('@/components/General/OrdenateIconHandler.vue'),
  },
  props: {
    tableRows: Array,
    getTypes: Function,
    tableHeaders: {
      type: Array,
      default: () => [
        'checkbox',
        'Arquivo',
        'Criação',
        'Convênio',
        'Nº Lote',
        'Nº Fatura',
        'Tipo de guia',
        'Nº guias',
        'Versão TISS',
        'Valor total',
        'Conferido',
        ' '
      ]
    },
    openHistoric: Function,
    getGuide: Function,
  },
  data() {
    return {
      tissBatchSelectedId: null,
      batchNumber: null,
      clinicHealthPlanName: '',
      selectAll: null,
      selected: [],
      colors: {
        XML: 'var(--light-orange-200)'
      },
      tissBatch: {},
      openedMenu: false,
      orderBy: {
        column: 'created_at',
        order: 'desc'
      },
      headerOrder: {
        "Criação": "created_at",
        "Nº Lote": "number",
        "Versão TISS": "xml_version",
        "Tipo de guia": "type"
      }
    }
  },
  methods: {
    openDelete(batch) {
      this.tissBatchSelectedId = batch.id
      this.batchNumber = batch['Nº Lote']
      this.clinicHealthPlanName = batch['Convênio']
      this.$bvModal.show('delete-tiss-modal')
    },
    async confirmDelete(confirm) {
      this.$bvModal.hide('delete-tiss-modal')
      if (confirm) await this.deleteTissBatch()
    },
    async deleteTissBatch() {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteTissBatch(this.tissBatchSelectedId)
        this.$emit('delete-batch', this.tissBatchSelectedId)
        this.$toast.success('Lote removido com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async findTissBatchById(id) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.findTissBatchById(id)
        this.tissBatch = response.data
        this.$bvModal.show('batch-modal')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    selectAllChecks() {
      this.selected = []
      if (!this.selectAll) {
        this.tableRows.forEach(row => {
          this.selected.push(row)
        })
      }
      this.$emit('selected-batches', this.selected)
    },
    selectCheck(row) {
      const index = this.selected.map(item => item.id).indexOf(row.id)
      if (index !== -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(row)
      }
    },
    handleOrder(key) {
      if (this.orderBy.column !== key) {
        this.$set(this.orderBy, 'column', key)
        this.$set(this.orderBy, 'order', 'asc')
        this.$emit('onOrdenate', this.orderBy)
        return
      }

      this.$set(this.orderBy, 'order', this.orderBy.order === 'asc' ? 'desc' : 'asc')
      this.$emit('onOrdenate', this.orderBy)
    }
  },
  watch: {
    selected: function (value) {
      this.$emit('selected-batches', value)
      if (!value.length) {
        this.selectAll = false
      }
    }
  }
}
</script>

<style lang="scss" src="@\assets\scss\table.scss" />

<style lang="scss">
#guides-table {
  position: relative;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  table-layout: fixed;

  thead {
    tr {
      background-color: var(--neutral-100);
    }
  }

  .hide-space {
    padding: 0px !important;
  }

  .spacing {
    height: 120px;
  }

  td:last-child {
    overflow: visible;
  }

  .conferred {
    color: var(--states-success-dark-green) !important;
  }

  .not-conferred {
    color: var(--light-orange-500);
  }


  td,
  th {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: 1368px) {
      font-size: 15px;
    }

    .checkbox {
      // margin-bottom: 40%;
    }

    .bold {
      font-weight: 700;
    }

    .green {
      background-color: var(--states-success-soft-green);
      color: var(--states-success-dark-green);
    }

    .blue {
      background-color: var(--blue-100);
      color: var(--blue-500);
    }

    .yellow {
      background-color: #fff6d4;
      color: #bc8212;
    }

    .orange {
      background-color: #fff3e1;
      color: var(--orange);
    }

    .red {
      background-color: var(--light-orange-200);
      color: var(--states-error);
    }

    .toggle-btn {
      background: var(--blue-100);
      border: none;
      padding: 5px !important;

      .icon {
        width: 25px;
        transition: all 500ms;
      }
    }
  }

  .options {
    cursor: pointer;
    width: 24px;
    height: 28px;
    position: relative;
    display: inline-block;

    .more-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      z-index: 10;
      width: 141px;
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
        0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      padding: 0;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-weight: 600;
        width: 100%;

        &.remove {
          color: var(--states-error);
        }

        &.btn-link {
          text-align: left;
          padding: 12px 18px !important;
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }

  .text-align-center {
    text-align: center;
  }

  .tag {
    border-radius: 30px;
    padding: 4px 10px;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>
